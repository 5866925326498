.container {
  padding: 1vw 8vw;
  justify-content: center;
  align-items: center;
}

.heading {
  display: flex;
  margin-top: 2vw;
  /* background-color: yellow; */
  height: 15vh;
}

.circle {
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  /* background-color: black; */
  padding: 3vw;
  /* margin-left: -6vw; */
  align-self: center;
  background-image: url("../Images/faceLogo.png");
  background-size: contain;
  background-position: center;
}

.texts {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  align-self: center;
}

.text1 {
  font-size: 1.5vw;
  padding: 0.5vw;
}

.text2 {
  font-size: 2.5vw;
  padding: 0.5vw;
  font-weight: bold;
}

.search {
  display: flex;
  padding: 0.2vw;
  margin-left: auto;
  justify-content: left;
}

.searchbar {
  display: flex;
  flex-direction: row;
  margin-right: 1vw;
  border: 1px solid black;
  height: 3vw;
  padding: 0 0.5vw;
}

.inputsearch {
  /* border-color: transparent; */
  outline: none;
  font-size: 1.5vw;
  border: none;
  text-align: center;
  padding: 0vw;
}

.camera {
  margin-left: auto;
  align-self: center;
  width: 3vw;
  margin-right: 4vw;
}

.newbutton {
  align-self: center;
  padding: 1vw 1.5vw;
  background-color: black;
  margin-right: 2vw;
  color: white;
  cursor: pointer;
  border: none;
  width: 8vw;
  border-radius: 2vw;
  text-align: center;
  font-size: 1.5vw;
  text-shadow: 0 0 1vw white;
}

.pdfhead {
  display: flex;
  flex-direction: row;
  margin-top: 2vw;
  /* background-color: yellow; */
  /* justify-content: right; */
  justify-content: space-between;
  align-items: center;
}

.prevreports {
  font-size: 1.8vw;
  font-weight: 500;
  margin-right: 1vw;
}

.listicon {
  height: 2.2vw;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.assessmentHeadContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
  width: 25vw;
  border-bottom: 0.1vw black solid;
  cursor: pointer;
}

.dropElement {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 4vw;
  padding: 0 1vw;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1vw black solid;
  border-left: 0.1vw black solid;
  border-right: 0.1vw black solid;
}

.dropdownIcon {
  height: 1.5vw;
  transform: scaleY(-1);
}

.assesmentText {
  font-size: 1.5vw;
}

.assesmentText1 {
  box-sizing: border-box;
  border: 1px solid black;
padding: 1vw;
  color: #04122c;
  font-size: 1.5vw;
  font-weight: 500;
}

.dropText {
  display: flex;
  font-size: 1.2vw;
  width: 75%;
  height: 100%;
  align-items: center;
  border-right: 0.1vw black solid;
}

.countText {
  font-size: 1.2vw;
}

.pdfAssessmentContainer {
  display: flex;
  justify-content: space-between;
}

.assessmentContainer {
  width: 25vw;
  height: fit-content;
  border-bottom: 0.1vw black solid;
}

.pdfcontainer {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
  height: 100%;
  width: 90vw;
}

.maincard {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: fit-content;
  width: 13vw;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 0 1vw;
  margin: 2vw;
  cursor: pointer;
  border-radius: 0.8vw;
}

.card {
  width: 6.5vw;
  margin: 1vw;
  /* border: 1px black solid; */
  margin: 0 auto;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.iconcont {
  width: 85%;
  align-self: center;
  border-radius: 2vw;
  /* padding: 0 -2vw 0 -2vw; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.pdfPages {
  overflow-y: scroll;
  overflow-anchor: auto;
  max-height: fit-content;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  scroll-behavior: initial;
}

.pdfPages::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

.cardcont {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
  grid-auto-flow: row;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  margin: 0;
  /* margin-top: calc(16vh + 8vw); */
}

.cardname {
  text-align: center;
  /* margin-left: 1vw; */
  /* display: flex; */
  flex-grow: 1;
  justify-content: center;
  font-size: 1vw;
}

.ptexts1 {
  text-align: center;
}
.ptexts {
  text-align: start;
}

.p1 {
  font-size: 1vw;
  font-weight: 500;
  margin-top: 1vw;
}

.p2 {
  font-size: 0.8vw;
  font-weight: 200;
  margin: 0.5vw;
}

.p21 {
  font-size: 1vw;
  font-weight: 200;
  margin: 0.5vw;
}
.ncont {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3vw;
}

.smallcircle {
  width: 10%;
  aspect-ratio: 1;
  border: 1px black solid;
  border-radius: 2vw;
  margin: auto 0;
  background-color: blue;
}

.signoutSec {
  display: flex;
  justify-content: flex-end;
  margin-top: 1vh;
}

.signout {
  box-sizing: border-box;
  margin-right: 2vw;
  border-style: none;
  border-radius: 5vw;
  background-color: black;
  color: white;
  padding: 1vw 2vw;
  font-size: 1.5vw;
  text-shadow: 0 0 1vw white;
}

.pageNavigationBar {
  height: 7vh;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}

.arrow {
  transform: scaleY(2);
  cursor: pointer;
  height: fit-content;
  font-size: 2vw;
}

.pageLinks {
  width: calc(50%);
  max-width: 500px;
  /* height: fit-content; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.circleDot {
  height: calc(5px + 1.2vh);
  width: calc(5px + 1.2vh);
  border-radius: 100%;
  background-color: black;
}

.testRow {
  display: flex;
  justify-content: space-between;
  margin-top: 6vw;
  margin-bottom: 6vw;
}

.miniCont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14vw;
  width: 40vw;
  margin: 0 5vw;
  margin-left: 0;
  margin-right: 0;
  background: linear-gradient(179.94deg, #04122C 0.05%, rgba(255, 255, 255, 0) 118.54%);
  border-radius: 1vw;
}

.testCont {
  box-sizing: border-box;
  padding: 1vw 0;
  display: flex;
  justify-content: center;
  width: 15vw;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 1vw;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2vw;
}
.testBtn {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-style: none;
  border-radius: 1vw;
  background-color: black;
  color: white;
  width: 15vw;
  padding: 1vw 0;
  font-weight: 500;
  font-size: 1.2vw;
    text-shadow: 0 0 1vw white;
  cursor: pointer;
}
.testBtn:hover {
    background-color: rgb(42, 42, 42);
    color: white;
}

@media (max-width: 768px) {
  .assessmentContainer {
    position: absolute;
    right: 0;
    background-color: white;
    margin-right: 8vw;
  }

  .signoutSec {
    bottom: 30;
  }

  .cardcont {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
    grid-auto-flow: row;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    margin: 0;
    /* margin-top: calc(16vh + 8vw); */
  }

  .maincard {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    gap: 1vw;
    height: 37vw;
    width: 25vw;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 0 1vw;
    margin: 3vw 2vw;
    cursor: pointer;
    border-radius: 0.8vw;
    padding: 1vw;
  }

  .cardname {
    text-align: center;
    /* margin-left: 1vw; */
    /* display: flex; */
    flex-grow: 1;
    justify-content: center;
    font-size: 2.5vw;
  }

  .iconcont {
    width: 75%;

    align-self: center;
    border-radius: 2vw;
    /* padding: 0 -2vw 0 -2vw; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .card {
    width: 12vw;
    margin: 1vw;
    /* border: 1px black solid; */
    margin: 0 auto;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .p1 {
    font-size: 2.1vw;
    font-weight: 500;
    margin-top: 1vw;
  }

  .p2 {
    font-size: 1.6vw;
    font-weight: 200;
    margin: 0.5vw;
  }

  .text1 {
    font-size: 2.4vw;
  }

  .text2 {
    font-size: 4.5vw;
    padding: 0.5vw;
    font-weight: bold;
  }

  .newbutton {
    align-self: center;
    padding: 1vw 1.5vw;
    background-color: black;
    margin-right: 2vw;
    color: white;
    cursor: pointer;
    border: none;
    width: 12vw;
    border-radius: 8vw;
    text-align: center;
    font-size: 2.5vw;
    text-shadow: 0 0 1vw white;
  }

  .prevreports {
    font-size: 2.8vw;
    font-weight: 500;
    margin-right: 1vw;
  }

  .pageInd {
    font-size: 2.5vw;
  }

  .circleDot {
    height: calc(5px + 0.5vw);
    width: calc(5px + 0.5vw);
    border-radius: 100%;
    background-color: black;
  }

  html {
    zoom: 1;
  }
}
