.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 1vw;
  overflow: scroll;
}
.typeContainer {
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0.7vw;
  box-shadow: 5px 5px 8px #888888;
  justify-content: space-between;
  cursor: pointer;
}
.typeName {
  font-size: 1.5vw;
}
.downButton {
  width: 1.5vw;
  object-fit: cover;
}
.dropItems {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1vw;
  box-sizing: border-box;
  padding: 1vw 3vw;
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18%;
  padding: 0.5vw;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8vw;
  text-align: center;
  height: 3vw;
}
.submitBtn {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.5vw 1vw;
  background-color: #04122c;
  color: white;
  border: none;
  border-radius: 2vw;
  font-size: 1vw;
  width: fit-content;
  align-self: center;
}
.finalSubmitBtn {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.5vw 1vw;
  background-color: #04122c;
  color: white;
  border: none;
  border-radius: 2vw;
  font-size: 1.5vw;
  width: fit-content;
  align-self: center;
}

@media (max-width: 768px) {
  .option {
    width: 25%;
    font-size: 1.4vw;
    height: 5vw;
  }
  .submitBtn {
    font-size: 1.5vw;
    padding: 1vw 2vw;
  }
  .finalSubmitBtn {
    font-size: 1.6vw;
    padding: 1vw 2vw;
  }
}
