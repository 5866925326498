.container {
  padding: 0vw 0.2vw;
  margin: 1vw 0vw;
}
.pageHeading {
  width: 100%;
  text-align: center;
  font-size: 2vw;
  font-weight: 500;
  transform: translateY(-50%);
}

.contentLayout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.alertDiv {
  height: fit-content;
  min-height: 5vh;
  width: 100%;
  font-size: 1.1vw;
  background-color: #f0f0f0;
  color: red;
  padding: 1.5vh 3vh;
  box-sizing: border-box;
  text-align: center;
  border: 2px solid #eaeaea;
  border-radius: 1vh;
  margin-bottom: 3%;
}

.mainContent {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: #f0f0f0; */
}

.image {
  height: 100%;
  width: 42%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  justify-content: center;
  align-items: center;
}

.image > img {
  height: 100%;
  width: 100%;
}

.detailsContainer {
  width: 55%;
  height: 35vw;
  background-color: #f0f0f0;
  /* border: 2px solid black; */
  padding: 3vh 1vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1vw;
}

.detailsContainer1 {
  width: 55%;
  height: 35vw;
  background-color: #f0f0f0;
  /* border: 2px solid black; */
  padding: 3vh 1vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1vw;
  overflow: scroll;
}

.formRow {
  width: 100%;
  height: 19%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.formElement {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formElementHalf {
  width: 21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.submitSec {
  width: 45%;
  margin-top: 2vw;
}

.inputLabel {
  margin: 0.5vw 0vw;
  padding-left: 1vw;
  font-size: 1.2vw;
}

.formInput {
  width: 100%;
  border-radius: 2vw;
  border: 0.13vw solid black;
  padding: 1vw;
  color: black;
  box-sizing: border-box;
  font-size: 1vw;
  background-color: white;
}
.healthInput {
  width: 100%;
  border-radius: 2vw;
  border: 0.13vw solid black;
  padding: 0.5vw;
  box-sizing: border-box;
  font-size: 1vw;
  background-color: white;
}

.recordBtn {
  width: 97%;
  border: none;
  border-radius: 2vw;
  background-color: black !important;
  color: white;
  padding: 1.2vw;
  cursor: pointer;
  font-size: 1vw;
}

.recordBtnDisabled1 {
  box-sizing: border-box;
  border-style: none;
  border-radius: 5vw;
  background-color: black;
  color: white;
  padding: 1vw 4vw;
  font-size: 1vw;
  text-shadow: 0 0 1vw white;
  cursor: pointer;
}

.recordBtnDisabled {
  width: 97%;
  border: none;
  border-radius: 2vw;
  background-color: rgb(68, 68, 68);
  color: white;
  padding: 1.2vw;
  cursor: pointer;
  font-size: 1vw;
}

.signoutSec {
  display: flex;
  /* width: 15vw; */
  /* width: 100%; */
  /* justify-content: flex-end; */
}
.signout {
  box-sizing: border-box;
  border-style: none;
  border-radius: 5vw;
  background-color: black;
  color: white;
  padding: 1vw 4vw;
  font-size: 1vw;
  height: 4vw;
  text-shadow: 0 0 1vw white;
  cursor: pointer;
}

.loadingOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: (66, 65, 67, 0.78);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.requiredStar {
  color: red;
  margin-left: 5px; /* Adjust margin as needed */
}

.optionSec {
  display: flex;
  justify-content: space-between;
}
.selected {
  border: 0.1vw solid black;
  border-radius: 2vw;
  background-color: grey;
  font-size: 1vw;
  color: black;
  padding: 0.5vw 1vw;
}
.notSelected {
  border: 0.1vw solid black;
  border-radius: 2vw;
  background-color: white;
  font-size: 1vw;
  color: black;
  padding: 0.5vw 1vw;
}
.selectedMed {
  box-sizing: border-box;
  border: 0.1vw solid black;
  border-radius: 1vw;
  background-color: grey;
  font-size: 0.8vw;
  padding: 0.5vw;
}
.notSelectedMed {
  border: 0.1vw solid black;
  border-radius: 1.5vw;
  background-color: white;
  font-size: 0.8vw;
  padding: 0.5vw;
}

.tncContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3vw;
}

.disclaimer {
  text-align: center;
}

.buttondiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 30vw;
  padding: 1vw;
}

.optionContainer {
  /* background-color: white; */
  padding: 0.8vw;
}

.optionLabel{
  font-size: 1.4vw;
  cursor: pointer;
}

.questionContainer {
  padding: 0.2vw 0.8vw;
  margin-bottom: 1.5vw;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
}

.questionContainer.elevated {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.optionContainer {
  display: flex;
  align-items: center;
  color: #04122c;
  font-size: 1vw;
  font-weight: 300;
}

.questionText {
  font-size: 1.5vw;
  padding: 0vw 1vw;
  color: #04122c;
  font-weight: 500;
}

.nextButton {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  background-color: #04122c;
  color: white;
  padding: 0.5vw 2vw;
  border-radius: 2vw;
}

.nextButton1 {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  background-color: #7f8592;
  color: white;
  padding: 0.5vw 2vw;
  border-radius: 2vw;
}

@media (max-width: 768px) {
  .mainContent {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .optionLabel{
    font-size: 1.8vw;
    cursor: pointer;
  }

  .questionText {
    font-size: 2.2vw;
    padding: 0vw 1vw;
    color: #04122c;
    font-weight: 500;
  }

  .alertDiv {
    width: 80%;
    margin: 0 auto;
    margin-top: 14vw;
    margin-bottom: 4vw;
    padding: 0vw;
    font-size: 1.9vw;
    line-height: 2vw;
    text-align: center;
    padding: 1vw;
  }
  .detailsContainer {
    margin-top: 7vw;
    margin-bottom: 0;
    width: 80%;
  }
  .detailsContainer1 {
    margin-top: 7vw;
    margin-bottom: 0;
    width: 80%;
    height: 50vw;
  }
  .inputLabel {
    font-size: 2vw;
  }
  .formRow {
    margin-top: 0.8vw;
    margin-bottom: 0.8vw;
  }
  .formInput {
    font-size: 1.5vw;
    /* background: transparent; */
  }
  .recordBtn {
    font-size: 1.5vw;
    margin-top: 2vw;
  }
  .contentLayout {
    transform: scale(1.2);
  }
  .pageHeading {
    font-size: 6vw;
    margin-top: 6vw;
  }

  .contentLayout {
    overflow: hidden;
  }

  .pageHeading {
    width: 100%;
    text-align: center;
    font-size: 6vw;
    margin-top: 10vw;
    font-weight: 500;
    transform: translateY(-50%);
  }

  .buttondiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 30vw;
    padding: 1vw;
  }

  .nextButton {
    display: flex;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    background-color: #04122c;
    color: white;
    padding: 0.5vw 2vw;
    font-size: 1.5vw;
    border-radius: 2vw;
  }

  .nextButton1 {
    display: flex;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    background-color: #7f8592;
    color: white;
    padding: 0.5vw 2vw;
    font-size: 1.5vw;
    border-radius: 2vw;
  }

  .back {
    margin: auto;
    width: 30%;
    border: none;
    border-radius: 2vw;
    background-color: black;
    color: white;
    padding: 1.2vw;
    cursor: pointer;
    font-size: 1.4vw;
    margin-bottom: 2vw;
  }

  html {
    zoom: 1;
  }
}
