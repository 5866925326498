.signout {
  box-sizing: border-box;
  border-style: none;
  border-radius: 5vw;
  background-color: black;
  color: white;
  padding: 1vw 2vw;
  font-size: 1.5vw;
  text-shadow: 0 0 1vw white;
  cursor: pointer;
}

@media (max-width:768px){
  .signout {
    box-sizing: border-box;
    border-style: none;
    border-radius: 5vw;
    background-color: black;
    color: white;
    padding: 1.5vw 2vw;
    font-size: 2.5vw;
    text-shadow: 0 0 1vw white;
    cursor: pointer;
  }
}