.header {
  display: flex;
  justify-content: space-between;
  /* background-color: yellow; */
}

.aivotLogo{
  height: 5vw;
  align-self: left;
}

@media (max-width: 768px) {
  .apolloLogo{
    height: 12vw;
}
.header {
  display: flex;
  height: 13vw;
}
.aivotLogo{
  height: 12vw;
  position: absolute;
  left:0;
  top:3vw;
}
}